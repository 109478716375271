import CN from 'clsx';

import styles from './styles.module.scss';

interface Props {
    className?: string;
}

const Loading = ({ className }: Props) => {
    return (
        <div className={CN(styles.container, className)}>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
        </div>
    );
};

export default Loading;
