import type { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header } from '@jaramba-frontend/core/components';

import { loginUser } from '../../api';
import { LoginForm } from '../../components';
import { routes } from '../../constants';
import { setAppLoading } from '../../store/features/app/appSlice';
import { setUser } from '../../store/features/user/userSlice';
import { useAppDispatch } from '../../store/hooks';
import styles from './styles.module.scss';

const Login = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [responseErrors, setResponseErrors] = useState<AxiosError | null>(null);

    const onInputsChange = () => {
        setResponseErrors(null);
    };

    const handleLogin = async (email: string, password: string, hasInputErrors: boolean) => {
        if (!responseErrors && !hasInputErrors) {
            setLoading(true);
            dispatch(setAppLoading(true));

            try {
                const userData = await loginUser(email, password);

                if (!userData) {
                    throw new Error('User data is empty');
                }

                const currentDateTime = new Date().toISOString();

                dispatch(setUser({ ...userData, lastLoggedInDate: currentDateTime }));
                navigate(routes.HOME, {
                    replace: true,
                });

                return;
            } catch (err) {
                setResponseErrors(err as AxiosError);
            } finally {
                setLoading(false);
                dispatch(setAppLoading(false));
            }
        }
    };

    return (
        <>
            <Header />
            <div className={styles.pageContent}>
                <h1 className={styles.title}>Logga in på Mina sidor</h1>
                <LoginForm
                    loading={loading}
                    responseErrors={responseErrors}
                    onInputsChange={onInputsChange}
                    onSubmit={handleLogin}
                />
            </div>
        </>
    );
};

export default Login;
