import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { SubscriptionInfo, User, UserInfo, UserStorageKey } from '../../../types';

export type StoredKeyValues = Record<UserStorageKey, string | null>;

interface UserState {
    user: (User & { subscriptionInfo?: SubscriptionInfo; storedKeyValues?: StoredKeyValues }) | null;
}

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
    } as UserState,
    reducers: {
        setUser: (state: UserState, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
        setUserInfo: (state: UserState, action: PayloadAction<UserInfo>) => {
            if (state.user) {
                state.user.userInfo = action.payload;
            }
        },
        setSubscriptionInfo: (state: UserState, action: PayloadAction<SubscriptionInfo>) => {
            if (state.user) {
                state.user.subscriptionInfo = action.payload;
            }
        },
        setStoredKeyValues: (state: UserState, action: PayloadAction<StoredKeyValues>) => {
            if (state.user) {
                state.user.storedKeyValues = action.payload;
            }
        },
        clearUser: (state: UserState) => {
            state.user = null;
        },
    },
});

export const { setUser, setUserInfo, setSubscriptionInfo, setStoredKeyValues, clearUser } = userSlice.actions;

export default userSlice.reducer;
