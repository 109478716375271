import { useEffect, RefObject } from 'react';

const useClickOutSide = (ref: RefObject<HTMLDivElement>, handleClose: () => void) => {
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref && ref?.current && !ref.current.contains(event.target)) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

export default useClickOutSide;
