import type { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Button, Input, Paper } from '@jaramba-frontend/core/components';
import { validateEmail } from '@jaramba-frontend/core/utils';

import { routes } from '../../constants';
import styles from './styles.module.scss';

interface Props {
    loading: boolean;
    responseErrors: AxiosError | null;
    onInputsChange: () => void;
    onSubmit: (email: string, password: string, hasInputErrors: boolean) => Promise<void>;
}

const LoginForm = ({ loading, responseErrors, onInputsChange, onSubmit }: Props) => {
    const {
        formState: { errors },
        getValues,
        watch,
        register,
        handleSubmit,
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: { email: '', password: '' },
    });

    const hasInputErrors = Object.values(errors).length > 0 ? Object.values(errors).every((value) => !!value) : false;
    const email = getValues()['email'].trim();
    const password = getValues()['password'].trim();

    useEffect(() => {
        onInputsChange();
    }, [watch('email'), watch('password')]);

    return (
        <Paper className={styles.formContainer}>
            <form
                className={styles.form}
                onSubmit={handleSubmit(async () => await onSubmit(email, password, hasInputErrors))}
            >
                <Input
                    {...register('email', {
                        required: 'Epostadress är obligatoriskt',
                        validate: {
                            isEmail: (value) => (validateEmail(value) ? true : 'Vänligen ange en giltig epostadress'),
                        },
                    })}
                    label="Din e-postadress"
                    type="email"
                    error={errors?.email?.message}
                    disabled={loading}
                    placeholder="email@jaramba.se"
                    autoComplete="email"
                />
                <Input
                    {...register('password', {
                        required: 'Lösenord är obligatoriskt',
                    })}
                    label="Ditt lösenord"
                    type="password"
                    placeholder="**********"
                    error={errors?.password?.message}
                    disabled={loading}
                    autoComplete="current-password"
                />

                {responseErrors?.message && (
                    <div className={styles.responseErrorsContainer}>
                        <p className={styles.responseError}>
                            {responseErrors.response?.status === 401
                                ? 'Felaktig epostadress eller lösenord'
                                : responseErrors.message}
                        </p>
                    </div>
                )}

                <Button
                    label="Logga in"
                    className={styles.submitButton}
                    disabled={loading || !!responseErrors}
                    type="submit"
                    fullWidth
                />
            </form>

            <p className={styles.forgotPasswordText}>
                Glömt lösenordet? <Link to={routes.FORGOT_PASSWORD}>Klicka</Link> här för att återställa.
            </p>
        </Paper>
    );
};

export default LoginForm;
