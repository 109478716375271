import { Suspense, lazy, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Tabs } from '@jaramba-frontend/core/components';

import { getSubscriptionInfo, getUserInfo, getUserStorageValue } from '../../api';
import { AppHeader, AccountInfo as AccountInfoTab, Subscription as SubscriptionTab } from '../../components';
import { setUserInfo, setSubscriptionInfo, setStoredKeyValues } from '../../store/features/user/userSlice';
import { useAppDispatch } from '../../store/hooks';
import type { RootState } from '../../store/store';
import { SubscriptionStatuses, UserStorageKey } from '../../types';
import { getAccountData } from '../../utils';

const AnnualNoticeModal = lazy(() => import('../../components/AnnualNoticeModal'));

const Home = () => {
    const dispatch = useAppDispatch();
    const { user } = useSelector((state: RootState) => state.user);
    const { showAnnualNoticeModal, isUserWithoutSubscription } = getAccountData({
        userInfo: user?.userInfo,
        subscriptionInfo: user?.subscriptionInfo,
        storedKeyValues: user?.storedKeyValues,
    });

    const updateUserInfo = async () => {
        try {
            const userInfo = await getUserInfo();
            dispatch(setUserInfo(userInfo));

            if (userInfo.ProductKind === SubscriptionStatuses.ActiveSubscription) {
                const subscriptionInfo = await getSubscriptionInfo();
                dispatch(setSubscriptionInfo(subscriptionInfo));
                const { shouldFetchStorage } = getAccountData({
                    userInfo,
                    subscriptionInfo,
                    storedKeyValues: user?.storedKeyValues,
                });

                if (shouldFetchStorage) {
                    try {
                        const newValue = await getUserStorageValue(UserStorageKey.AnnualNoticeModalNextBillingDate);
                        dispatch(
                            setStoredKeyValues({
                                annualNoticeModalNextBillingDate: newValue,
                            })
                        );
                    } catch {
                        dispatch(
                            setStoredKeyValues({
                                annualNoticeModalNextBillingDate: null,
                            })
                        );
                    }
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        updateUserInfo();
    }, []);

    return (
        <>
            <AppHeader title="Mitt konto" />
            <Tabs
                activeTabIndex={Number(isUserWithoutSubscription)}
                tabs={[
                    {
                        title: 'Mina uppgifter',
                        content: <AccountInfoTab />,
                    },
                    {
                        title: 'Prenumeration',
                        content: <SubscriptionTab onUpdateUserInfo={updateUserInfo} />,
                    },
                ]}
            />

            {showAnnualNoticeModal && (
                <Suspense fallback={null}>
                    <AnnualNoticeModal />
                </Suspense>
            )}
        </>
    );
};

export default Home;
