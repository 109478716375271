import type { AnalyticsEvent } from '@jaramba-frontend/core/types';

import { apiService } from '.';

export const sendAnalyticsEvent = async (event: AnalyticsEvent) =>
    await apiService.post<void, AnalyticsEvent>(
        '/events',
        {
            ...event,
            Data: event.Data || {},
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
            apiVersion: 4,
        }
    );
