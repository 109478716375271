import { default as _Cookie, CookieSetOptions } from 'universal-cookie';

import { isClient } from '../utils';

class Cookie extends _Cookie {
    private readonly oldCookie: _Cookie = new _Cookie();

    set = (name: string, value: any, options: CookieSetOptions = {}) => {
        this.oldCookie.set(name, value, { domain: this.domain(), ...options });
    };

    remove = (name: string, options: CookieSetOptions = {}) => {
        this.oldCookie.remove(name, { domain: this.domain(), ...options });
    };

    removeAll = () => {
        const allCookies = this.oldCookie.getAll();
        Object.keys(allCookies).forEach((cookieName) => {
            this.remove(cookieName, { path: '/' });
        });
    };

    private domain = () => {
        const hostNameArr = isClient() ? window.location.hostname.split('.') : [];
        let sliceIndex = 0;

        for (let i = hostNameArr.length - 1; i >= 0; i--) {
            if (hostNameArr[i].match(/jaramba/g)) {
                sliceIndex = i;
                break;
            }
        }

        return hostNameArr.slice(sliceIndex).join('.');
    };
}

const CookieService = new Cookie();

export default CookieService;
