import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { v4, v5 } from 'uuid';

import type { DataLayerEvent } from '../types';
import { isDevelopment } from '../utils';
import CookieService from './cookie.service';

const IS_ALLOWED_IN_DEV = false;

class Analytics {
    private initialized: boolean;
    private userData: Record<string, string> | null;

    constructor() {
        const auth = CookieService.get('auth');

        this.initialized = false;
        this.userData = auth?.name && auth?.userId ? { email_address: auth?.name } : {};
    }

    public init(gtmConfig: TagManagerArgs) {
        if (isDevelopment() && !IS_ALLOWED_IN_DEV) return;

        if (gtmConfig.gtmId) {
            TagManager.initialize(gtmConfig);
            this.initialized = true;
        }
    }

    public tagEvents = (events: DataLayerEvent[]) => {
        try {
            for (const event of events) {
                this.tagEvent(event);
            }
        } catch (e: any) {
            console.error(e);
            throw new Error('Failed to send events to dataLayer');
        }
    };

    public tagEvent = (event: DataLayerEvent) => {
        if (isDevelopment() && !IS_ALLOWED_IN_DEV) return;

        let lastSentEvent = '';
        const key = v4();
        const eventId = v5(JSON.stringify(event), key);

        if (lastSentEvent === eventId) {
            return;
        }

        lastSentEvent = eventId;

        const { action, category, label, ...rest } = event;

        if (this.initialized) {
            try {
                TagManager.dataLayer({
                    dataLayer: {
                        gaEventAction: action,
                        gaEventCategory: category,
                        gaEventLabel: label,
                        user_data: this.userData,
                        ...rest,
                    },
                });
            } catch (e: any) {
                console.error(e);
                throw new Error('Failed to send events to dataLayer');
            }
        }
    };

    public tagPage = async (page: string) => {
        if (isDevelopment() && !IS_ALLOWED_IN_DEV) return;

        const uniqueEventId = v4();

        if (this.initialized) {
            try {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'pageView',
                        uniqueEventId,
                        page,
                        user_data: this.userData,
                    },
                });
            } catch (e: any) {
                console.error(e);
                throw new Error('Failed to send page view event to dataLayer');
            }
        }
    };
}

const AnalyticsService = new Analytics();

export default AnalyticsService;
