import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { ConsentBanner } from '@jaramba-frontend/core/components';

import { AuthRoute, PrivateRoute } from '..';
import { AppLayout, AppWrapper } from '../../components';
import { routes } from '../../constants';
import { Download, ForgotPassword, Home, Login, NotFound, ResetForgottenPassword } from '../../pages';

const AppRouter = () => {
    return (
        <BrowserRouter>
            <AppWrapper>
                <Routes>
                    <Route path="*" element={<Navigate to={routes.NOT_FOUND} replace />} />
                    <Route path={routes.NOT_FOUND} element={<NotFound />} />
                    <Route
                        path={routes.LOGIN}
                        element={
                            <AuthRoute>
                                <>
                                    <ConsentBanner />
                                    <Login />
                                </>
                            </AuthRoute>
                        }
                    />
                    <Route
                        path={routes.FORGOT_PASSWORD}
                        element={
                            <AuthRoute>
                                <>
                                    <ConsentBanner />
                                    <ForgotPassword />
                                </>
                            </AuthRoute>
                        }
                    />
                    <Route
                        path={routes.RESET_PASSWORD}
                        element={
                            <AuthRoute>
                                <>
                                    <ConsentBanner />
                                    <ResetForgottenPassword />
                                </>
                            </AuthRoute>
                        }
                    />
                    <Route
                        path={routes.HOME}
                        element={
                            <PrivateRoute>
                                <AppLayout />
                            </PrivateRoute>
                        }
                    >
                        <Route path={routes.HOME} element={<Home />} />
                        <Route path={routes.DOWNLOAD} element={<Download />} />
                    </Route>
                </Routes>
            </AppWrapper>
        </BrowserRouter>
    );
};

export default AppRouter;
