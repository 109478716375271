import { lazy, PropsWithChildren, Suspense, useLayoutEffect } from 'react';

import { CONSENT_INIT_SCRIPT } from '@jaramba-frontend/core/constants';
import { useAnalytics, useTimedPageRefresh } from '@jaramba-frontend/core/hooks';

import { useAppSelector } from '../../store/hooks';
import { appSelector } from '../../store/selectors';

const LoadingScreen = lazy(() => import('../LoadingScreen'));

const AppWrapper = ({ children }: PropsWithChildren) => {
    const { loading: appLoading } = useAppSelector(appSelector);
    useTimedPageRefresh();
    useAnalytics();

    useLayoutEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = CONSENT_INIT_SCRIPT;
        document.head.prepend(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <>
            {appLoading && (
                <Suspense fallback={null}>
                    <LoadingScreen />
                </Suspense>
            )}

            {children}
        </>
    );
};

export default AppWrapper;
