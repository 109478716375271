export enum EventDestination {
    Mixpanel = 'Mixpanel',
    Braze = 'Braze',
}

export interface AnalyticsEvent {
    Name: string;
    /** ISO string */
    Timestamp: string;
    Destinations: EventDestination[];
    Data?: Record<string, any>;
}
