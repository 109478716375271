import type { Roles } from './auth';
import type { PaymentProviders, SubscriptionStatuses } from './subscription';

export interface User {
    email: string;
    token: string;
    lastLoggedInDate: string;
    refreshToken?: string;
    tokenExpirationDate?: string;
    roles?: Roles[];
    userInfo?: UserInfo;
}

export interface UserInfo {
    AccountId: number;
    AlbertAccountId: string | null;
    UserName: string;
    ProductName: string | null;
    /** Indicates subscription status */
    ProductKind: SubscriptionStatuses;
    IsSubscriptionCancelled: boolean;
    PaymentProvider: PaymentProviders | null;
    NextPayment: string;
    LastDayOfService: string;
    Price: string;
}

export enum UserStorageKey {
    AnnualNoticeModalNextBillingDate = 'annualNoticeModalNextBillingDate',
}

export interface UserStoredValue {
    key: UserStorageKey;
    value: string;
    expires: string | null;
}
