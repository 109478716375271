import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { CookieService } from '@jaramba-frontend/core/services';

import { getUserInfo } from '../../api';
import { routes } from '../../constants';
import { useLogout } from '../../hooks';
import { setUserInfo } from '../../store/features/user/userSlice';
import { useAppDispatch } from '../../store/hooks';
import type { RootState } from '../../store/store';
import type { UserInfo } from '../../types';

interface Props {
    children: JSX.Element;
}

const PrivateRoute = ({ children }: Props) => {
    const { user } = useSelector((state: RootState) => state.user);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { handleLogout } = useLogout();

    const handleGetUserInfo = async (): Promise<UserInfo> => {
        try {
            const userInfo = await getUserInfo();

            if (!user?.userInfo) {
                dispatch(setUserInfo(userInfo));
            }
            return userInfo;
        } catch (e) {
            console.error(e);
            throw new Error('Failed to get user info');
        }
    };

    useEffect(() => {
        const hoursLimit = 1000 * 60 * 60 * 2;
        const currentDateTime = dayjs();
        const lastLoggedInDateTime = dayjs(user?.lastLoggedInDate);
        const timeLeft = hoursLimit - currentDateTime.diff(lastLoggedInDateTime);

        if (!user?.userInfo && user?.token) {
            (async () => {
                const userInfo = await handleGetUserInfo();

                if (!CookieService.get('auth')) {
                    const cookie = {
                        userId: userInfo?.AccountId,
                        name: user?.email ?? '',
                    };

                    CookieService.set('auth', cookie);
                }
            })();
        }

        const loginTimeOut = setTimeout(() => {
            handleLogout();
        }, timeLeft);

        return () => {
            clearTimeout(loginTimeOut);
        };
    }, []);

    if (!user) {
        return <Navigate replace={true} to={routes.LOGIN} state={{ from: location }} />;
    }

    return children;
};

export default PrivateRoute;
