interface Params {
    query: string;
    variables?: Record<string, any>;
}

export default class DatoCMS {
    static requestContent = async ({ query, variables }: Params) => {
        const environment = process.env.REACT_APP_DATOCMS_ENVIRONMENT ?? process.env.NEXT_PUBLIC_DATOCMS_ENVIRONMENT;
        const environmentPath = environment ? `environments/${environment}` : '';
        const endpoint = `https://graphql.datocms.com/${environmentPath}`;
        const authHeader = {
            authorization: `Bearer ${
                process.env.NEXT_PUBLIC_DATOCMS_API_TOKEN || process.env.REACT_APP_DATOCMS_API_TOKEN
            }`,
        };

        try {
            const res = await fetch(endpoint, {
                method: 'POST',
                body: JSON.stringify({
                    query,
                    variables,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader,
                },
            });

            const { data } = await res.json();

            return data;
        } catch (e) {
            console.error(e);
            return null;
        }
    };
}
