import type { AxiosError } from 'axios';
import { useState } from 'react';

import { Paper } from '@jaramba-frontend/core/components';

import { changePassword } from '../../api';
import { useAppSelector } from '../../store/hooks';
import { userSelector } from '../../store/selectors';
import ChangePasswordForm from '../ChangePasswordForm';
import AccountDeletionInfo from './AccountDeletionInfo';
import styles from './styles.module.scss';

const AccountInfo = () => {
    const user = useAppSelector(userSelector);

    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [responseErrors, setResponseErrors] = useState<Partial<AxiosError> | null>(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleTogglePasswordChangeForm = (show: boolean) => {
        setShowChangePassword(show);
    };

    const onInputsChange = () => {
        setResponseErrors(null);
    };

    const handleChangePassword = async (
        currentPassword: string,
        confNewPassword: string,
        hasInputErrors: boolean,
        reset: () => void
    ) => {
        if (!responseErrors && !hasInputErrors) {
            setLoading(true);

            try {
                const res = await changePassword(currentPassword, confNewPassword);

                if (res !== 'OK') {
                    setResponseErrors({ message: 'Something went wrong' } as AxiosError);
                    return;
                }

                setShowSuccessMessage(true);
                reset();
                return;
            } catch (err) {
                setResponseErrors(err as AxiosError);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className={styles.container}>
            <Paper className={styles.card}>
                <h4 className={styles.cardSubtitle}>E-postadress</h4>
                <p className={styles.cardText}>{user?.email}</p>

                <div className={styles.separator} />

                <h4 className={styles.cardSubtitle}>Lösenord</h4>

                {!showChangePassword ? (
                    <button className={styles.cardButton} onClick={() => handleTogglePasswordChangeForm(true)}>
                        Ändra lösenord
                    </button>
                ) : (
                    <ChangePasswordForm
                        loading={loading}
                        showSuccessMessage={showSuccessMessage}
                        responseErrors={responseErrors}
                        onInputsChange={onInputsChange}
                        onCancelButtonClick={() => handleTogglePasswordChangeForm(false)}
                        onSubmit={handleChangePassword}
                    />
                )}
            </Paper>

            <AccountDeletionInfo />
        </div>
    );
};

export default AccountInfo;
