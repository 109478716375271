import { useEffect, useState } from 'react';

import { isServer } from '../utils';
import useDidMount from './useDidMount';

const useMediaMatch = (query: string) => {
    const didMount = useDidMount();
    const [isMediaMatch, setIsMediaMatch] = useState(isServer() ? false : window.matchMedia(query).matches);

    useEffect(() => {
        setIsMediaMatch(didMount ? window.matchMedia(query).matches : false);
    }, [didMount, query]);

    const resizeHandler = (): void => {
        const mediaQueryResize = window.matchMedia(query).matches;
        if (isMediaMatch !== mediaQueryResize) {
            setIsMediaMatch(mediaQueryResize);
        }
    };

    useOnWindowResize(resizeHandler);

    return didMount ? isMediaMatch : false;
};

const useOnWindowResize = (callback: (event: Event) => void, listenerOptions: AddEventListenerOptions = {}) => {
    useEffect(() => {
        window.addEventListener('resize', callback, listenerOptions);
        return (): void => window.removeEventListener('resize', callback, listenerOptions);
    }, [callback, listenerOptions]);
};

export default useMediaMatch;
