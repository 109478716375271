import { ReactComponent as AndroidIcon } from '@jaramba-frontend/core/assets/icons/android.svg';
import { ReactComponent as AppleIcon } from '@jaramba-frontend/core/assets/icons/apple.svg';
import { Button, Paper } from '@jaramba-frontend/core/components';

import styles from './styles.module.scss';

interface Props {
    store: 'apple' | 'google';
    onButtonClick: () => void;
}

const AppStoreCard = ({ store, onButtonClick }: Props) => {
    return (
        <Paper className={styles.appStoreCard}>
            {store === 'apple' ? (
                <>
                    <AppleIcon className={styles.platformIcon} />

                    <div className={styles.cardInfoCol}>
                        <h3 className={styles.title}>iOS</h3>
                        <p className={styles.subtitle}>App Store</p>
                    </div>
                </>
            ) : (
                <>
                    <AndroidIcon className={styles.platformIcon} />

                    <div className={styles.cardInfoCol}>
                        <h3 className={styles.title}>Android</h3>
                        <p className={styles.subtitle}>Google Play Store</p>
                    </div>
                </>
            )}

            <Button className={styles.button} onClick={onButtonClick}>
                Ladda ner app
            </Button>
        </Paper>
    );
};

export default AppStoreCard;
