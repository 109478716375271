import CN from 'clsx';

import jarambaLogoSvg from '../../assets/icons/jaramba-logo.svg';
import styles from './styles.module.scss';

interface Props {
    className?: string;
}

const JarambaLogo = ({ className }: Props) => {
    return <img src={jarambaLogoSvg} className={CN(styles.root, className)} draggable={false} />;
};

export default JarambaLogo;
