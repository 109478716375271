import dayjs from 'dayjs';

import { BillingIntervals } from '@jaramba-frontend/core/types';

import type { StoredKeyValues } from '../store/features/user/userSlice';
import { SubscriptionInfo, SubscriptionStatuses, PaymentProviders, UserInfo } from '../types';

export const getAccountData = ({
    userInfo,
    subscriptionInfo,
    storedKeyValues,
}: {
    userInfo?: UserInfo;
    subscriptionInfo?: SubscriptionInfo;
    storedKeyValues?: StoredKeyValues;
}) => {
    const hasActiveSubscription = userInfo?.ProductKind === SubscriptionStatuses.ActiveSubscription;
    const isUserWithoutSubscription =
        userInfo?.ProductKind === SubscriptionStatuses.NoSubscription ||
        userInfo?.ProductKind === SubscriptionStatuses.CancelledSubscription;
    const isRegisteredMoreThanDayAgo = !!dayjs().diff(dayjs(subscriptionInfo?.created), 'day');
    const isNextPaymentLessThanMonth = !dayjs().diff(dayjs(subscriptionInfo?.nextBillingDate), 'month');
    const isNextPaymentLessThanFiveDays = !!(dayjs().diff(dayjs(subscriptionInfo?.nextBillingDate), 'day') > -5);
    const hasStripeProvider = userInfo?.PaymentProvider === PaymentProviders.Stripe;
    const isAnnualBilling = subscriptionInfo?.billingInterval?.toLowerCase() === BillingIntervals.Annualy;
    const showAnnualNoticeModal =
        hasStripeProvider &&
        hasActiveSubscription &&
        isAnnualBilling &&
        subscriptionInfo?.autoRenewalEnabled &&
        ((isNextPaymentLessThanMonth && isRegisteredMoreThanDayAgo) || isNextPaymentLessThanFiveDays) &&
        (storedKeyValues?.annualNoticeModalNextBillingDate === null ||
            (storedKeyValues?.annualNoticeModalNextBillingDate &&
                storedKeyValues.annualNoticeModalNextBillingDate !== subscriptionInfo.nextBillingDate));

    const shouldFetchStorage =
        hasStripeProvider &&
        hasActiveSubscription &&
        isAnnualBilling &&
        subscriptionInfo?.autoRenewalEnabled &&
        ((isNextPaymentLessThanMonth && isRegisteredMoreThanDayAgo) || isNextPaymentLessThanFiveDays) &&
        (storedKeyValues?.annualNoticeModalNextBillingDate === undefined ||
            (storedKeyValues.annualNoticeModalNextBillingDate &&
                storedKeyValues.annualNoticeModalNextBillingDate !== subscriptionInfo.nextBillingDate));

    return {
        hasActiveSubscription,
        isUserWithoutSubscription,
        hasStripeProvider,
        isAnnualBilling,
        isRegisteredMoreThanDayAgo,
        isNextPaymentLessThanMonth,
        isNextPaymentLessThanFiveDays,
        showAnnualNoticeModal,
        shouldFetchStorage,
    };
};
