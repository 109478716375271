import { CookieService } from '@jaramba-frontend/core/services';

import { setAppLoading } from '../store/features/app/appSlice';
import { clearProduct } from '../store/features/product/productSlice';
import { clearUser } from '../store/features/user/userSlice';
import { useAppDispatch } from '../store/hooks';

const useLogout = () => {
    const dispatch = useAppDispatch();

    const handleLogout = () => {
        dispatch(setAppLoading(true));

        dispatch(clearUser());
        dispatch(clearProduct());
        CookieService.remove('auth');

        dispatch(setAppLoading(false));
        sessionStorage.clear();
    };

    return {
        handleLogout,
    };
};

export default useLogout;
