import type { AxiosError } from 'axios';

import { apiService } from '.';
import { getStoreState } from '../store/store';
import type { UserStoredValue, UserInfo, UserStorageKey } from '../types';

export const getUserInfo = async (tokenParam?: string): Promise<UserInfo> => {
    const token = tokenParam ?? getStoreState().user.user?.token;

    try {
        const userInfo = await apiService.get<UserInfo>('/webapp/accounts/info', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            apiVersion: 4,
        });

        if (!userInfo) {
            return Promise.reject();
        }

        return Promise.resolve(userInfo);
    } catch (err) {
        return Promise.reject(err as AxiosError);
    }
};

export const sendValueToUserStorage = async (storageValueData: UserStoredValue): Promise<void> => {
    try {
        await apiService.post<void, UserStoredValue>(
            '/webapp/storage',
            { ...storageValueData },
            {
                apiVersion: 4,
            }
        );

        return Promise.resolve();
    } catch (err) {
        return Promise.reject(err as AxiosError);
    }
};

export const getUserStorageValue = async (key: UserStorageKey): Promise<UserStorageKey> => {
    try {
        const storedValue = await apiService.get<UserStorageKey>(`/webapp/storage/${key}`, {
            apiVersion: 4,
        });

        if (!storedValue) {
            return Promise.reject();
        }

        return Promise.resolve(storedValue);
    } catch (err) {
        return Promise.reject(err as AxiosError);
    }
};
