import appStoreQrCode from '@jaramba-frontend/core/assets/images/appstore-app-qr.png';
import googlePlayQrCode from '@jaramba-frontend/core/assets/images/google-play-app-qr.png';
import { Button, Modal } from '@jaramba-frontend/core/components';

import styles from './styles.module.scss';

interface Props {
    isOpen: boolean;
    store: 'apple' | 'google' | null;
    onModalClose: () => void;
}

const AppQrModal = ({ isOpen, store, onModalClose }: Props) => {
    return (
        <Modal isOpen={isOpen} contentClass={styles.modalContent} onClose={onModalClose}>
            <h2 className={styles.title}>Jaramba app</h2>

            <p className={styles.description}>
                För att ladda ner appen, scanna QR koden med den enhet du vill ladda ner appen på.
            </p>

            {store === 'apple' ? (
                <>
                    <img src={appStoreQrCode} className={styles.qrCode} alt="AppStore QR code." />
                    <p className={styles.label}>iOS</p>
                </>
            ) : (
                <>
                    <img src={googlePlayQrCode} className={styles.qrCode} alt="Google Play QR code." />
                    <p className={styles.label}>Android</p>
                </>
            )}

            <Button className={styles.button} fullWidth onClick={onModalClose}>
                Close
            </Button>
        </Modal>
    );
};

export default AppQrModal;
