import { Button } from '@jaramba-frontend/core/components';
import { BillingIntervals } from '@jaramba-frontend/core/types';

import { BILLING_TEXT } from '../../constants';
import styles from './styles.module.scss';

interface Props {
    price: string;
    billingInterval: BillingIntervals;
    offerText?: string;
    savingAmount?: string;
    originalAnnualPrice?: string;
    showYearlyAsMonthly?: boolean;
    onSelectPlanClick: (billingInterval: BillingIntervals) => void;
}

const ProductCard = ({
    price,
    billingInterval,
    offerText,
    savingAmount,
    originalAnnualPrice,
    showYearlyAsMonthly,
    onSelectPlanClick,
}: Props) => {
    const isAnnualBilling = billingInterval === BillingIntervals.Annualy;

    return (
        <div className={styles.card}>
            <div className={styles.cardHeader}>
                <h3 className={styles.plan}>{BILLING_TEXT[billingInterval].full}</h3>
                {isAnnualBilling && <p className={styles.label}>Mest populär</p>}
            </div>
            <div className={styles.cardBody}>
                <h4 className={styles.price}>
                    <span>{price}</span>/
                    {BILLING_TEXT[showYearlyAsMonthly ? BillingIntervals.Monthly : billingInterval].short}
                </h4>
                {offerText && <p className={styles.offerText}>{offerText}</p>}

                <p className={styles.details}>
                    {isAnnualBilling && (
                        <span className={styles.savingAmount}>
                            Spara {savingAmount}/{BILLING_TEXT[billingInterval].full}
                        </span>
                    )}
                    <span className={styles.billingInterval}>
                        Faktureras {BILLING_TEXT[billingInterval].full} {originalAnnualPrice ?? price}
                    </span>
                </p>

                <Button className={styles.button} fullWidth onClick={() => onSelectPlanClick(billingInterval)}>
                    Välj {BILLING_TEXT[billingInterval].full}
                </Button>
            </div>
        </div>
    );
};

export default ProductCard;
