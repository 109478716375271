import type { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Input } from '@jaramba-frontend/core/components';
import { inputPasswordValidationRules } from '@jaramba-frontend/core/constants';

import styles from './styles.module.scss';

interface Props {
    loading: boolean;
    showSuccessMessage: boolean;
    responseErrors: Partial<AxiosError> | null;
    onInputsChange: () => void;
    onCancelButtonClick: () => void;
    onSubmit: (
        currentPassword: string,
        confNewPassword: string,
        hasInputErrors: boolean,
        reset: () => void
    ) => Promise<void>;
}

const ChangePasswordForm = ({
    loading,
    responseErrors,
    showSuccessMessage,
    onSubmit,
    onInputsChange,
    onCancelButtonClick,
}: Props) => {
    const {
        formState: { errors },
        watch,
        getValues,
        register,
        handleSubmit,
        reset,
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: { currentPassword: '', newPassword: '', confNewPassword: '' },
    });

    const hasInputErrors = Object.values(errors).length > 0 ? Object.values(errors).every((value) => !!value) : false;

    useEffect(() => {
        onInputsChange();
    }, [watch('newPassword'), watch('currentPassword'), watch('confNewPassword')]);

    return (
        <form
            className={styles.form}
            onSubmit={handleSubmit(
                async () =>
                    await onSubmit(
                        getValues()['currentPassword'].trim(),
                        getValues()['confNewPassword'].trim(),
                        hasInputErrors,
                        reset
                    )
            )}
        >
            <Input
                {...register('currentPassword', {
                    required: 'Lösenord är obligatoriskt',
                    ...inputPasswordValidationRules,
                })}
                label="Nuvarande lösenord"
                type="password"
                placeholder="**********"
                error={errors?.currentPassword?.message}
                disabled={loading}
                autoComplete="current-password"
            />
            <Input
                {...register('newPassword', {
                    required: 'Nytt lösenord är obligatoriskt',
                    ...inputPasswordValidationRules,
                })}
                label="Nytt lösenord"
                type="password"
                placeholder="**********"
                error={errors?.newPassword?.message}
                disabled={loading}
                autoComplete="new-password"
            />
            <Input
                {...register('confNewPassword', {
                    required: 'Bekräfta nytt lösenord är obligatoriskt',
                    ...inputPasswordValidationRules,
                    validate: (value) => value === watch('newPassword') || 'Lösenorden stämmer inte överens',
                })}
                label="Bekräfta nytt lösenord"
                type="password"
                placeholder="**********"
                error={errors?.confNewPassword?.message}
                disabled={loading}
                autoComplete="new-password"
            />

            {responseErrors?.message && (
                <div className={styles.responseErrorsContainer}>
                    <p className={styles.responseError}>{responseErrors.message}</p>
                </div>
            )}

            {showSuccessMessage && !loading && !responseErrors && (
                <p className={styles.successMessage}>Ditt lösenord har ändrats</p>
            )}

            <div className={styles.buttonsContainer}>
                <Button
                    label="Avbryt"
                    disabled={loading || !!responseErrors}
                    type="submit"
                    fullWidth
                    outlined
                    onClick={onCancelButtonClick}
                />

                <Button label="Bekräfta lösenordsändring" disabled={loading || !!responseErrors} fullWidth />
            </div>
        </form>
    );
};

export default ChangePasswordForm;
