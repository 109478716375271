import CN from 'clsx';
import { useRef, useEffect, useState, Fragment, ReactNode, useCallback } from 'react';

import styles from './styles.module.scss';

interface Tab {
    title: ReactNode;
    content: ReactNode;
}

interface Props {
    tabs: Tab[];
    activeTabIndex?: number;
    disabled?: boolean;
    containerClass?: string;
    tabButtonsClass?: string;
    tabButtonClass?: string;
    tabIndicatorClass?: string;
}

const Tabs = ({
    tabs,
    activeTabIndex,
    disabled,
    containerClass,
    tabButtonsClass,
    tabButtonClass,
    tabIndicatorClass,
}: Props) => {
    const [activeTab, setActiveTab] = useState<number>(activeTabIndex ?? 0);
    const indicatorRef = useRef<HTMLDivElement>(null);
    const tabRefs = useRef<Record<string, HTMLButtonElement | null>>({});

    useEffect(() => {
        updateIndicatorPosition(activeTab, true);
    }, []);

    useEffect(() => {
        setActiveTab(activeTabIndex ?? 0);
    }, [activeTabIndex]);

    useEffect(() => {
        updateIndicatorPosition(activeTab);
    }, [activeTab, tabRefs.current[activeTab]?.offsetWidth]);

    const handleOpenTab = useCallback(
        (tabId: number) => {
            setActiveTab(tabId);
            updateIndicatorPosition(tabId);
        },
        [activeTab]
    );

    const updateIndicatorPosition = (tabId: number, isFirstLoad?: boolean) => {
        if (indicatorRef.current && tabRefs.current[tabId]) {
            const tabElement = tabRefs.current[tabId];
            const { offsetLeft, offsetWidth } = tabElement as HTMLButtonElement;

            indicatorRef.current.style.transition = isFirstLoad ? 'none' : '';
            indicatorRef.current.style.transform = `translateX(${offsetLeft / 10}rem)`;
            indicatorRef.current.style.width = `${offsetWidth / 10}rem`;
        }
    };

    return (
        <div className={containerClass}>
            <div className={CN(styles.tabButtons, tabButtonsClass)}>
                <div ref={indicatorRef} className={CN(styles.indicator, tabIndicatorClass)} />

                {tabs.map((tab, index) => (
                    <button
                        key={`key_${index}`}
                        ref={(ref) => {
                            tabRefs.current[index] = ref;
                        }}
                        className={CN(styles.tabButton, tabButtonClass, {
                            [styles.active]: index === activeTab,
                        })}
                        disabled={disabled}
                        onClick={() => handleOpenTab(index)}
                    >
                        {tab.title}
                    </button>
                ))}
            </div>

            {tabs.map((tab, index) => {
                if (activeTab !== index) {
                    return null;
                }

                return <Fragment key={`key_${index}`}>{tab.content}</Fragment>;
            })}
        </div>
    );
};

export default Tabs;
