import dayjs from 'dayjs';
import { useState, useRef, useEffect } from 'react';

import { CONSENT_SETTINGS_COOKIE_NAME, WEBSITE_URL } from '../../constants';
import { AnalyticsService, CookieService } from '../../services';
import { ConsentSettings } from '../../types';
import { isClient } from '../../utils';
import Button from '../Button';
import ConsentSettingsModal from './ConsentSettingsModal';
import styles from './styles.module.scss';

declare global {
    interface Window {
        gtag: (...args: any[]) => void;
    }
}

const ConsentBanner = () => {
    const consentBannerRef = useRef<HTMLDivElement>(null);
    const [showConsentBanner, setShowConsentBanner] = useState<boolean>(
        () => isClient() && !CookieService.get(CONSENT_SETTINGS_COOKIE_NAME)
    );
    const [showConsentSettingsModal, setShowConsentSettingsModal] = useState<boolean>(false);

    useEffect(() => {
        if (showConsentBanner) {
            consentBannerRef.current?.focus();
        }
    }, [showConsentBanner, showConsentSettingsModal]);

    const setConsentSettings = ({ marketingCookies, analyticsCookies }: ConsentSettings) => {
        const consentSettings = {
            ad_storage: marketingCookies,
            ad_user_data: marketingCookies,
            ad_personalization: marketingCookies,
            analytics_storage: analyticsCookies,
        };

        CookieService.set(CONSENT_SETTINGS_COOKIE_NAME, JSON.stringify(consentSettings), {
            path: '/',
            expires: dayjs().add(1, 'year').toDate(),
        });
        window?.gtag('consent', 'update', consentSettings);

        AnalyticsService.tagEvent({
            event: 'consent_settings',
            consentSettings: {
                marketingCookies,
                analyticsCookies,
            },
        });

        setShowConsentBanner(false);
    };

    const handleShowConsentSettingsModalClick = () => {
        setShowConsentSettingsModal(true);
    };

    const handleCloseConsentSettingsModalClick = () => {
        setShowConsentSettingsModal(false);
    };

    const handleClickAcceptAllClick = () => {
        setConsentSettings({
            analyticsCookies: 'granted',
            marketingCookies: 'granted',
        });
    };

    const handleDeclineAllClick = () => {
        setConsentSettings({
            analyticsCookies: 'denied',
            marketingCookies: 'denied',
        });
    };

    if (showConsentSettingsModal) {
        return (
            <ConsentSettingsModal
                onSaveSettings={setConsentSettings}
                onModalClose={handleCloseConsentSettingsModalClick}
            />
        );
    }

    if (!showConsentBanner) {
        return null;
    }

    return (
        <div className={styles.consentBanner} ref={consentBannerRef} tabIndex={0} role="banner" aria-modal="true">
            <div className={styles.paper}>
                <div className={styles.content}>
                    <div className={styles.cookieEmoji}>🍪</div>
                    <p className={styles.text}>
                        Vi använder cookies för att ge dig en mer personlig upplevelse på denna webbplats. Klicka på
                        "Hantera cookies" för att ändra dina alternativ. Läs mer om våra cookies{' '}
                        <a href={`${WEBSITE_URL}policy#Vadarencookie`} className={styles.learnMoreLink}>
                            här
                        </a>
                        .
                    </p>
                </div>
                <div className={styles.buttonsContainer}>
                    <Button className={styles.button} onClick={handleClickAcceptAllClick}>
                        Acceptera alla
                    </Button>
                    <Button className={styles.button} onClick={handleDeclineAllClick}>
                        Avvisa alla
                    </Button>
                    <Button className={styles.button} outlined onClick={handleShowConsentSettingsModalClick}>
                        Hantera cookies
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConsentBanner;
