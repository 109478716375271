export const CONSENT_SETTINGS_COOKIE_NAME = 'consent_settings';

export const CONSENT_INIT_SCRIPT = `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                
                const getCookieValue = (cookieName) => {
                    let name = cookieName + "=";
                    let decodedCookie = decodeURIComponent(document.cookie);
                    let cookieArray = decodedCookie.split(';');
                    for (let i = 0; i < cookieArray.length; i++) {
                        let cookie = cookieArray[i].trim();
                        if (cookie.indexOf(name) == 0) {
                            return cookie.substring(name.length, cookie.length);
                        }
                    }
                    return "";
                }

                const consentSettingsCookie = getCookieValue("${CONSENT_SETTINGS_COOKIE_NAME}");
                
                if (!consentSettingsCookie) {
                    gtag('consent', 'default', {
                        ad_storage: 'denied',
                        ad_user_data: 'denied',
                        ad_personalization: 'denied',
                        analytics_storage: 'denied',
                    });
                } else {
                    gtag('consent', 'default', JSON.parse(consentSettingsCookie));
                }`;
