import CN from 'clsx';
import { memo, useRef, useState } from 'react';

import { useClickOutside } from '@jaramba-frontend/core/hooks';

import { useLogout } from '../../hooks';
import { useAppSelector } from '../../store/hooks';
import { userSelector } from '../../store/selectors';
import styles from './styles.module.scss';

const AccountDropdown = () => {
    const wrapperRef = useRef(null);
    const user = useAppSelector(userSelector);
    const { handleLogout } = useLogout();
    useClickOutside(wrapperRef, () => handleCloseDropdown());

    const [isOpen, setOpen] = useState<boolean>(false);

    const handleToggleDropdown = () => {
        setOpen(!isOpen);
    };

    const handleCloseDropdown = () => {
        setOpen(false);
    };

    return (
        <div ref={wrapperRef} className={styles.root}>
            <button className={styles.accountDropdownButton} onClick={handleToggleDropdown}>
                <div className={styles.avatar}>{user?.email[0]}</div>
                <span className={styles.email}>{user?.email}</span>
                <div
                    className={CN(styles.triangleIcon, {
                        [styles.reversed]: isOpen,
                    })}
                />
            </button>

            {isOpen && (
                <ul className={styles.dropdownMenu}>
                    <li className={styles.menuItem}>{user?.email}</li>
                    <li>
                        <button className={styles.button} onClick={handleLogout}>
                            Logga ut
                        </button>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default memo(AccountDropdown);
