import { BillingIntervals } from '../types';

export const mobileBreakpoint = '(max-width: 767px)';

export const GROWTHBOOK_UID_COOKIE_NAME = 'gb_user_id';

export const BILLING_TEXT = {
    [BillingIntervals.Monthly]: {
        full: 'månadsvis',
        short: 'mån',
    },
    [BillingIntervals.Annualy]: {
        full: 'årligen',
        short: 'år',
    },
};
