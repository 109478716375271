import qrCodeBanner from '@jaramba-frontend/core/assets/images/qr-code-app-banner.jpg';
import { Paper } from '@jaramba-frontend/core/components';

import styles from './styles.module.scss';

const AppQrBannerCard = () => {
    return (
        <Paper className={styles.qrCodeCard}>
            <img
                className={styles.qrCodeCardBanner}
                src={qrCodeBanner}
                alt="Application QR code banner."
                draggable={false}
            />

            <div className={styles.qrCodeCardContent}>
                <p className={styles.label}>iOS + Android</p>
                <p className={styles.description}>
                    För att ladda ner appen, scanna QR koden med den enhet du vill ladda ner appen på.
                </p>
            </div>
        </Paper>
    );
};

export default AppQrBannerCard;
