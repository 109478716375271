export enum BillingIntervals {
    Annualy = 'year',
    Monthly = 'month',
}

export interface Price {
    billingInterval: 'Year' | 'Month';
    currency: string;
    key: string;
    unitAmount: number;
    unitAmountFormatted: string;
}

export interface Product {
    name: string;
    description: string;
    prices: Price[];
}

export interface Plan {
    billingInterval: BillingIntervals;
    offerText?: string;
}

export interface Campaign {
    name: string;
    debitDate: string | null;
    plans: Plan[];
    pageTitle?: string;
    pageSubtitle?: string;
}

export interface GetProductsResponse {
    products: Product[];
}

export enum Campaigns {
    Active = 'active',
    Reactivate = 'reactivate',
}
