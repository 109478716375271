import routes from './routes';

export const navigationLinks = [
    {
        label: 'Mitt konto',
        pathname: routes.HOME,
    },
    {
        label: 'Nedladdning',
        pathname: routes.DOWNLOAD,
    },
];
