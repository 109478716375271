import { mobileBreakpoint } from '@jaramba-frontend/core/constants';
import { useMediaMatch } from '@jaramba-frontend/core/hooks';

import AccountDropdown from '../AccountDropdown';
import styles from './styles.module.scss';

interface Props {
    title: string;
}

const AppHeader = ({ title }: Props) => {
    const isMobile = useMediaMatch(mobileBreakpoint);

    return (
        <header className={styles.root}>
            <h1 className={styles.title}>{title}</h1>

            {!isMobile && <AccountDropdown />}
        </header>
    );
};

export default AppHeader;
