import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Campaign, Price } from '@jaramba-frontend/core/types';

type Product = {
    campaign: Campaign;
    prices: Price[];
};
interface ProductState {
    product: Product | null;
}

export const productSlice = createSlice({
    name: 'product',
    initialState: {
        product: null,
    } as ProductState,
    reducers: {
        setProduct: (state: ProductState, action: PayloadAction<Product>) => {
            state.product = action.payload;
        },
        clearProduct: (state: ProductState) => {
            state.product = null;
        },
    },
});

export const { setProduct, clearProduct } = productSlice.actions;

export default productSlice.reducer;
