import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppState {
    loading: boolean;
}

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        loading: false,
    } as AppState,
    reducers: {
        setAppLoading: (state: AppState, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { setAppLoading } = appSlice.actions;

export default appSlice.reducer;
