import type { AnalyticsEvent, EventDestination } from '../types';

class Event implements AnalyticsEvent {
    public readonly Timestamp: string;

    constructor(public Name: string, public Destinations: EventDestination[], public Data?: Record<string, any>) {
        this.Timestamp = new Date().toISOString();
    }
}

export default Event;
