import type { AxiosError } from 'axios';
import { useState } from 'react';

import { Header } from '@jaramba-frontend/core/components';

import { forgotPassword } from '../../api';
import { ForgotPasswordForm } from '../../components';
import styles from './styles.module.scss';

const ForgotPassword = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [responseErrors, setResponseErrors] = useState<Partial<AxiosError> | null>(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

    const onEmailChange = () => {
        setResponseErrors(null);
    };

    const handleRequestForgottenPassword = async (email: string, hasInputErrors: boolean) => {
        if (!responseErrors && !hasInputErrors) {
            setLoading(true);

            try {
                const res = await forgotPassword(email);

                if (res === 1) {
                    setShowSuccessMessage(true);
                } else if (res === 2) {
                    setResponseErrors({
                        message: 'Den angivna e-postadressen är inte registrerad',
                    });
                } else {
                    setResponseErrors({
                        message: 'Något gick fel',
                    });
                }
            } catch {
                setResponseErrors({
                    message: 'Något gick fel',
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Header />
            <div className={styles.pageContent}>
                <h1 className={styles.title}>Återställ lösenord</h1>
                <p className={styles.description}>
                    Fyll i den e-postadress du registrerade dig med så skickar vi ett meddelande med instruktioner om
                    hur du återställer ditt lösenord.
                </p>

                <ForgotPasswordForm
                    loading={loading}
                    showSuccessMessage={showSuccessMessage}
                    responseErrors={responseErrors}
                    onEmailChange={onEmailChange}
                    onSubmit={handleRequestForgottenPassword}
                />
            </div>
        </>
    );
};

export default ForgotPassword;
