import { ReactComponent as JarambaAppIcon } from '@jaramba-frontend/core/assets/icons/jaramba-app.svg';
import { Button, Paper } from '@jaramba-frontend/core/components';
import { JARAMBA_TARGET_AGES, mobileBreakpoint } from '@jaramba-frontend/core/constants';
import { useMediaMatch } from '@jaramba-frontend/core/hooks';

import styles from './styles.module.scss';

interface Props {
    onButtonClick: () => void;
}

const JarambaAppCard = ({ onButtonClick }: Props) => {
    const isMobile = useMediaMatch(mobileBreakpoint);

    return (
        <Paper className={styles.jarambaAppCard}>
            <JarambaAppIcon className={styles.jarambaIcon} />

            <div className={styles.cardInfoCol}>
                <h2 className={styles.title}>Jaramba</h2>
                <p className={styles.subtitle}>ABC 123 &amp; lek {JARAMBA_TARGET_AGES} år</p>
            </div>

            {isMobile && (
                <Button className={styles.button} onClick={onButtonClick}>
                    Ladda ner app
                </Button>
            )}
        </Paper>
    );
};

export default JarambaAppCard;
