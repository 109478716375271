import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { getUserInfo } from '../../api';
import { routes } from '../../constants';
import { setAppLoading } from '../../store/features/app/appSlice';
import { setUser } from '../../store/features/user/userSlice';
import { useAppDispatch } from '../../store/hooks';
import type { RootState } from '../../store/store';

interface Props {
    children: JSX.Element;
}

const AuthRoute = ({ children }: Props) => {
    const { user } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();

    const token = searchParams.get('token');
    const urlTicket = searchParams.get('id');

    const handleClearSerachParams = () => {
        navigate(
            {
                search: '',
            },
            { replace: true }
        );
    };

    const handleAuthenticateWithToken = async (token: string) => {
        try {
            const userInfo = await getUserInfo(token);

            if (!userInfo) {
                handleClearSerachParams();
            }

            dispatch(
                setUser({
                    token,
                    userInfo,
                    email: userInfo.UserName,
                    lastLoggedInDate: new Date().toISOString(),
                })
            );
        } catch (e) {
            handleClearSerachParams();

            console.error(e);
            throw new Error('Failed to get user info');
        }
    };

    useEffect(() => {
        if (searchParams.has('campaignName')) {
            sessionStorage.setItem('campaignName', searchParams.get('campaignName')!);
        }

        if (!user && token) {
            (async () => {
                try {
                    dispatch(setAppLoading(true));
                    await handleAuthenticateWithToken(token);
                } catch (err: any) {
                    console.error(err);
                    throw new Error('Failed to authenticate with token');
                } finally {
                    dispatch(setAppLoading(false));
                }
            })();
        }

        if (pathname === routes.RESET_PASSWORD && !user && !urlTicket) {
            navigate(routes.LOGIN, {
                replace: true,
            });
        }
    }, []);

    useEffect(() => {
        if (user) {
            navigate(routes.HOME, {
                replace: true,
            });
        }
    }, [user]);

    return children;
};

export default AuthRoute;
