import { isProduction } from '../utils';

export const appStoreLinks = {
    appStore: 'https://apps.apple.com/se/app/jaramba-abc-123-lek-2-5-%C3%A5r/id963081897',
    googlePlay: 'https://play.google.com/store/apps/details?id=air.com.streamience.jaramba',
};

export const WEBSITE_URL = isProduction()
    ? 'https://www.jaramba.se/'
    : 'https://website-development-un7h4.ondigitalocean.app/';
