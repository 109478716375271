import { apiService } from '.';
import type { AbTest, SetUserAbTestsRequest } from '../types';

export const setUserAbTests = async (abTests: AbTest[], token: string) =>
    await apiService.post<void, SetUserAbTestsRequest>(
        '/useraccounts/AssignToTestGroups',
        { TestGroups: abTests },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            apiVersion: 3,
        }
    );
