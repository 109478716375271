import { SharedApiService } from '@jaramba-frontend/core/services';

const getJarambaClient = async () => {
    return {
        'jaramba-client': process.env.NEXT_PUBLIC_JARAMBA_CLIENT ?? process.env.REACT_APP_JARAMBA_CLIENT,
    };
};

export const apiService = new SharedApiService([getJarambaClient]);

export * from './campaign';
export * from './payment';
export * from './user';
