import type { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header } from '@jaramba-frontend/core/components';

import { changeForgottenPassword } from '../../api';
import { ResetForgottenPasswordForm } from '../../components';
import { routes } from '../../constants';
import { setUser } from '../../store/features/user/userSlice';
import { useAppDispatch } from '../../store/hooks';
import type { Roles, User } from '../../types';
import styles from './styles.module.scss';

const ResetForgottenPassword = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [responseErrors, setResponseErrors] = useState<Partial<AxiosError> | null>(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [userState, setUserState] = useState<User | null>(null);
    const [isExpiredTicket, setIsExpiredTicket] = useState<boolean>(false);

    const onInputsChange = () => {
        setResponseErrors(null);
    };

    const handleChangeForgottenPassword = async (
        urlTicket: string | null,
        newPassword: string,
        hasInputErrors: boolean
    ) => {
        if (!responseErrors && !hasInputErrors && urlTicket) {
            setLoading(true);

            try {
                const res = await changeForgottenPassword(urlTicket, newPassword);

                if (res?.Token) {
                    setShowSuccessMessage(true);

                    const currentDateTime = new Date().toISOString();

                    setUserState({
                        email: res.Name as string,
                        token: res.Token,
                        refreshToken: res.RefreshToken,
                        tokenExpirationDate: res.TokenExpirationDate,
                        roles: res.Roles as Roles[],
                        lastLoggedInDate: currentDateTime,
                    });
                } else if (res.Response === 2) {
                    setIsExpiredTicket(true);
                    setResponseErrors({
                        message:
                            'Begäran att återställa lösenordet är inte längre giltig, vänligen återställ lösenordet på nytt.',
                    });
                } else {
                    setResponseErrors({
                        message: 'Något gick fel. Prova igen senare.',
                    });
                }
            } catch {
                setResponseErrors({
                    message: 'Något gick fel. Prova igen senare.',
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const handleAuthenticateUser = () => {
        if (userState) {
            dispatch(setUser(userState));
        }
    };

    const handleRedirectToForgotPassword = () => {
        navigate(routes.FORGOT_PASSWORD, {
            replace: true,
        });
    };

    return (
        <>
            <Header />
            <div className={styles.pageContent}>
                <h1 className={styles.title}>Skapa nytt lösenord</h1>

                <ResetForgottenPasswordForm
                    loading={loading}
                    showSuccessMessage={showSuccessMessage}
                    userState={userState}
                    isExpiredTicket={isExpiredTicket}
                    responseErrors={responseErrors}
                    onInputsChange={onInputsChange}
                    onLoginToPortalClick={handleAuthenticateUser}
                    onForgotPasswordClick={handleRedirectToForgotPassword}
                    onSubmit={handleChangeForgottenPassword}
                />
            </div>
        </>
    );
};

export default ResetForgottenPassword;
