import { useRef, useEffect, ReactNode, RefObject } from 'react';

import { useClickOutside } from '@jaramba-frontend/core/hooks';

import styles from './styles.module.scss';

interface Props {
    isMenuOpen: boolean;
    content: ReactNode;
    headerRef: RefObject<HTMLDivElement>;
    onMenuClose: () => void;
}

const MobileMenu = ({ isMenuOpen, content, headerRef, onMenuClose }: Props) => {
    const mobileMenuRef = useRef<HTMLDivElement>(null);
    useClickOutside(headerRef, () => onMenuClose());

    const handleEscapeKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && isMenuOpen) {
            onMenuClose();
        }
    };

    useEffect(() => {
        if (isMenuOpen) {
            mobileMenuRef.current?.focus();
            document.body.classList.add(styles.noScroll);
        } else {
            document.body.classList.remove(styles.noScroll);
        }

        document.addEventListener('keydown', handleEscapeKeyPress);

        return () => {
            document.removeEventListener('keydown', handleEscapeKeyPress);
        };
    }, [isMenuOpen]);

    return (
        <nav
            ref={mobileMenuRef}
            className={`${styles.navbarMenu} ${isMenuOpen ? styles.show : styles.hide}`}
            role="menu"
            aria-hidden={!isMenuOpen}
        >
            {content}
        </nav>
    );
};

export default MobileMenu;
