import type { AxiosError } from 'axios';

import { apiService } from '.';
import type {
    ChangeForgottenPasswordRequest,
    ChangeForgottenPasswordResponse,
    ChangePasswordRequest,
    ChangePasswordResponse,
    ForgotPasswordRequest,
    ForgotPasswordResponse,
    User,
    UserLoginRequest,
    UserLoginResponse,
} from '../types';

export const loginUser = async (email: string, password: string): Promise<Omit<User, 'lastLoggedInDate'>> => {
    try {
        const user = await apiService.post<UserLoginResponse, UserLoginRequest>(
            '/webapp/accounts/login',
            {
                Username: email.trim(),
                Password: password.trim(),
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (!user) {
            return Promise.reject();
        }

        return Promise.resolve({
            email: user.Name,
            token: user.Token,
            refreshToken: user.RefreshToken,
            tokenExpirationDate: user.TokenExpirationDate,
            roles: user.Roles,
        });
    } catch (err) {
        return Promise.reject(err as AxiosError);
    }
};

export const changePassword = async (currentPassword: string, newPassword: string): Promise<string> => {
    try {
        const res = await apiService.post<ChangePasswordResponse, ChangePasswordRequest>(
            '/webapp/accounts/changePassword',
            {
                Password: currentPassword.trim(),
                NewPassword: newPassword.trim(),
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (!res) {
            return Promise.reject();
        }

        return Promise.resolve(res.Status);
    } catch (err) {
        return Promise.reject(err as AxiosError);
    }
};

export const forgotPassword = async (email: string) => {
    try {
        const res = await apiService.post<ForgotPasswordResponse, ForgotPasswordRequest>(
            '/webapp/accounts/forgotpassword',
            {
                Email: email,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!res.Response) {
            return Promise.reject();
        }

        return Promise.resolve(res.Response);
    } catch (err) {
        return Promise.reject(err as AxiosError);
    }
};

export const changeForgottenPassword = async (urlTicket: string, newPassword: string) => {
    try {
        const res = await apiService.post<ChangeForgottenPasswordResponse, ChangeForgottenPasswordRequest>(
            '/webapp/accounts/changeforgottenpassword',
            {
                UrlTicket: urlTicket,
                NewPassword: newPassword,
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (!res) {
            return Promise.reject();
        }

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err as AxiosError);
    }
};
