import { useNavigate } from 'react-router-dom';

import { Header, PageNotFound } from '@jaramba-frontend/core/components';

import { routes } from '../../constants';

const NotFound = () => {
    const navigate = useNavigate();

    const handleRedirectHome = () => {
        navigate(routes.HOME, {
            replace: true,
        });
    };

    return (
        <>
            <Header />
            <PageNotFound onHomeButtonClick={handleRedirectHome} />
        </>
    );
};

export default NotFound;
