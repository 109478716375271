import { Event } from '@jaramba-frontend/core/services';
import { BillingIntervals, EventDestination } from '@jaramba-frontend/core/types';

import { SubscriptionStatuses } from './subscription';

export const cancelSubscriptionButtonClickEvent = (data: {
    has_discount: boolean;
    in_trial: boolean;
    subscription_period: BillingIntervals;
}) => new Event('cancel_subscription_button_click', [EventDestination.Mixpanel, EventDestination.Braze], data);

export const manageSubscriptionButtonClickEvent = (data: {
    subscription_status: SubscriptionStatuses;
    has_discount: boolean;
    in_trial: boolean;
    subscription_period: BillingIntervals;
}) => new Event('manage_subscription_button_click', [EventDestination.Mixpanel, EventDestination.Braze], data);

export const churnOfferEvent = (data: {
    name: string;
    coupon_id: string;
    in_trial: boolean;
    subscription_period: BillingIntervals;
    discount_type: string;
    discount_status: 'discount_accepted' | 'discount_cancelled';
}) => new Event('churn_offer', [EventDestination.Mixpanel, EventDestination.Braze], data);

export const subscriptionCancelledEvent = (data: {
    has_discount: boolean;
    in_trial: boolean;
    subscription_period: BillingIntervals;
}) => new Event('cancel_subscription', [EventDestination.Mixpanel, EventDestination.Braze], data);

export const cancelWarningModalEvent = (data: { action: 'cancellation_continued' | 'cancellation_aborted' }) =>
    new Event('cancel_warning_popup', [EventDestination.Mixpanel, EventDestination.Braze], data);

export const trialCancelWarningModalEvent = (data: {
    action: 'cancelation_continued' | 'cancelation_aborted';
    days_after_registration: number;
}) => new Event('trial_cancel_warning_popup', [EventDestination.Mixpanel, EventDestination.Braze], data);
