import { ReactComponent as AttentionIcon } from '@jaramba-frontend/core/assets/icons/attention-stop.svg';
import { Button, Modal } from '@jaramba-frontend/core/components';

import styles from './styles.module.scss';

interface Props {
    isTrialUsersAbTest: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

const CancelWarningModal = ({ isTrialUsersAbTest, onConfirm, onClose }: Props) => {
    const content = {
        title: 'Är du säker på att du vill avsluta?',
        defaultText: 'Vill du gå vidare med att avsluta din prenumeration?',
        trialText:
            'Om du avslutar din prenumeration nu, avbryts din provperiod direkt. Du kommer omedelbart att förlora tillgång till appen och möjligheten att fortsätta med Jarambas övningar.',
    };

    return (
        <Modal isOpen contentClass={styles.modalContent} onClose={onClose}>
            <AttentionIcon className={styles.icon} />
            <h3 className={styles.title}>{content.title}</h3>
            <p className={styles.text}>{isTrialUsersAbTest ? content.trialText : content.defaultText}</p>
            <div className={styles.buttonsContainer}>
                <Button className={styles.buttonOutlined} onClick={onClose}>
                    Avbryt
                </Button>
                <Button onClick={onConfirm}>Bekräfta uppsägning</Button>
            </div>
        </Modal>
    );
};

export default CancelWarningModal;
