import { useState } from 'react';

import crossIcon from '@jaramba-frontend/core/assets/icons/cross.svg';

import { Button, Modal, ToggleSwitch } from '../';
import { WEBSITE_URL } from '../../constants';
import type { ConsentSettingValue, ConsentSettings } from '../../types';
import styles from './styles.module.scss';

interface Props {
    onSaveSettings: (args: ConsentSettings) => void;
    onModalClose: () => void;
}

const ConsentSettingsModal = ({ onSaveSettings, onModalClose }: Props) => {
    const [analyticsCookies, setAnalyticsCookies] = useState<ConsentSettingValue>('denied');
    const [marketingCookies, setMarketingCookies] = useState<ConsentSettingValue>('denied');

    const handleSaveButtonClick = () => {
        onSaveSettings({
            marketingCookies,
            analyticsCookies,
        });
        onModalClose();
    };

    const handleDeclineAllButtonClick = () => {
        onSaveSettings({
            marketingCookies: 'denied',
            analyticsCookies: 'denied',
        });
        onModalClose();
    };

    return (
        <Modal
            isOpen
            contentClass={styles.modal}
            overlayClass={styles.modalOverlay}
            customCloseButton={
                <button className={styles.modalCloseButton} onClick={onModalClose}>
                    {/* @ts-ignore */}
                    <img src={crossIcon.src || crossIcon} loading="lazy" draggable="false" />
                </button>
            }
            onClose={onModalClose}
        >
            <div className={styles.scrollContainer}>
                <div className={styles.modalHeader}>
                    <h2 className={styles.title}>Hantera cookies</h2>
                    <p className={styles.subtitle}>
                        Välj dina inställningar och läs mer om hur varje kategori används. För mer information se vår{' '}
                        <a
                            href={`${WEBSITE_URL}policy#Vadarencookie`}
                            className={styles.learnMoreLink}
                            onClick={onModalClose}
                        >
                            Cookiepolicy
                        </a>
                        .
                    </p>
                </div>

                <div className={styles.contentSettings}>
                    <div className={styles.setting}>
                        <div className={styles.nameContainer}>
                            <p className={styles.name}>Nödvändiga cookies</p>
                            <ToggleSwitch disabled checked />
                        </div>
                        <p className={styles.description}>
                            Nödvändiga cookies krävs för att aktivera de grundläggande funktionerna på den här
                            webbplatsen, till exempel säker inloggning eller justering av dina samtyckespreferenser.
                            Nödvändiga cookies lagrar inte några personligt identifierbara uppgifter.
                        </p>
                    </div>

                    <div className={styles.setting}>
                        <div className={styles.nameContainer}>
                            <p className={styles.name}>Cookies för analys</p>
                            <ToggleSwitch
                                checked={analyticsCookies === 'granted'}
                                onChange={(e) => setAnalyticsCookies(e.target.checked ? 'granted' : 'denied')}
                            />
                        </div>
                        <p className={styles.description}>
                            Analyscookies är nödvändiga för att förstå och förbättra besökarnas interaktion med
                            webbplatsen. De ger insikter i besökarstatistik, t.ex. trafikkällor och avvisningsfrekvens,
                            medan prestandacookies analyserar viktiga prestandaindex för att förbättra den övergripande
                            användarupplevelsen.
                        </p>
                    </div>

                    <div className={styles.setting}>
                        <div className={styles.nameContainer}>
                            <p className={styles.name}>Cookies för marknadsföring</p>
                            <ToggleSwitch
                                checked={marketingCookies === 'granted'}
                                onChange={(e) => setMarketingCookies(e.target.checked ? 'granted' : 'denied')}
                            />
                        </div>
                        <p className={styles.description}>
                            Vi använder marknadsföringscookies för att anpassa annonser och kommunikation som vi tror
                            att du kan vara intresserad av. Vi samlar in data om dina surfvanor och de sidor du tidigare
                            har besökt och baserar riktad kommunikation på det.
                        </p>
                    </div>
                </div>
            </div>

            <div className={styles.buttonsContainer}>
                <Button className={styles.button} onClick={handleDeclineAllButtonClick}>
                    Avvisa alla
                </Button>
                <Button className={styles.button} onClick={handleSaveButtonClick}>
                    Spara ändringar
                </Button>
            </div>
        </Modal>
    );
};

export default ConsentSettingsModal;
