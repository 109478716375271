import { DatoCMS } from '@jaramba-frontend/core/services';
import type { BillingIntervals } from '@jaramba-frontend/core/types';

import { apiService } from '.';
import type { ChurnOfferInfo } from '../types';

export const getChurnOffer = async (
    billingInterval: BillingIntervals,
    subscriptionType: 'trial' | 'paying'
): Promise<ChurnOfferInfo> => {
    const data: { offer: ChurnOfferInfo } = await DatoCMS.requestContent({
        query: `query GetChurnOffer($filter: OfferModelFilter) {
            offer(filter: $filter) {
              id
              name
              title
              description
              couponId
            }
          }`,
        variables: {
            filter: {
                billingInterval: {
                    eq: billingInterval,
                },
                subscriptionType: {
                    eq: subscriptionType,
                },
            },
        },
    });

    return data?.offer;
};

export const applyOffer = async (offerName: string): Promise<void> => {
    try {
        const res = apiService.post(
            '/payments/stripe/apply-offer',
            { name: offerName },
            {
                setApiVersionInHeader: true,
                apiVersion: 2,
            }
        );

        return Promise.resolve(res as any);
    } catch (err) {
        return Promise.reject(err);
    }
};
