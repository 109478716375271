import CN from 'clsx';
import { NavLink } from 'react-router-dom';

import { JarambaLogo } from '@jaramba-frontend/core/components';

import { ReactComponent as DownloadsIcon } from '../../assets/icons/downloads.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { navigationLinks, routes } from '../../constants';
import styles from './styles.module.scss';

const Sidebar = () => {
    return (
        <div className={styles.root}>
            <div className={styles.logoContainer}>
                <NavLink to={routes.HOME}>
                    <JarambaLogo className={styles.logoIcon} />
                </NavLink>
            </div>

            <ul className={styles.menu}>
                {navigationLinks.map((menuLink) => (
                    <li key={menuLink.label.toLowerCase()}>
                        <NavLink
                            to={menuLink.pathname}
                            className={({ isActive }) =>
                                CN(styles.menuItemLink, {
                                    [styles.active]: isActive,
                                })
                            }
                        >
                            {menuLink.pathname === routes.HOME && <UserIcon />}
                            {menuLink.pathname === routes.DOWNLOAD && <DownloadsIcon />}

                            <span>{menuLink.label}</span>
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
