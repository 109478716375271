const routes = {
    HOME: '/',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    NOT_FOUND: '/404',
    DOWNLOAD: '/download',
};

export default routes;
