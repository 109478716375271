import type { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Input, Paper } from '@jaramba-frontend/core/components';
import { inputPasswordValidationRules } from '@jaramba-frontend/core/constants';
import { validateEmail } from '@jaramba-frontend/core/utils';

import styles from './styles.module.scss';

interface Props {
    loading: boolean;
    showSuccessMessage: boolean;
    responseErrors: Partial<AxiosError> | null;
    onEmailChange: () => void;
    onSubmit: (email: string, hasInputErrors: boolean) => Promise<void>;
}

const ForgotPasswordForm = ({ loading, showSuccessMessage, responseErrors, onEmailChange, onSubmit }: Props) => {
    const {
        formState: { errors },
        watch,
        register,
        handleSubmit,
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: { email: '' },
    });

    const hasInputErrors = Object.values(errors).length > 0 ? Object.values(errors).every((value) => !!value) : false;

    useEffect(() => {
        onEmailChange();
    }, [watch('email')]);

    return (
        <Paper className={styles.formContainer}>
            {showSuccessMessage ? (
                <p className="text-center">
                    Instruktioner för återställning av lösenord har skickats till den angivna e-postadressen.
                </p>
            ) : (
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(async () => await onSubmit(watch('email'), hasInputErrors))}
                >
                    <Input
                        {...register('email', {
                            required: 'Epostadress är obligatoriskt',
                            ...inputPasswordValidationRules,
                            validate: {
                                isEmail: (value) =>
                                    validateEmail(value) ? true : 'Felaktigt e-postformat eller domän',
                            },
                        })}
                        label="Din e-postadress"
                        type="email"
                        error={errors?.email?.message}
                        disabled={loading}
                        placeholder="email@jaramba.se"
                        autoComplete="email"
                    />

                    {responseErrors?.message && (
                        <div className={styles.responseErrorsContainer}>
                            <p className={styles.responseError}>{responseErrors.message}</p>
                        </div>
                    )}

                    <Button
                        label="Skicka"
                        className={styles.submitButton}
                        disabled={loading || !!responseErrors}
                        type="submit"
                        fullWidth
                    />
                </form>
            )}
        </Paper>
    );
};

export default ForgotPasswordForm;
