export enum PaymentProviders {
    Stripe = 'stripe',
    SwedbankPay = 'swedbankpay',
    GooglePlay = 'google',
    Itunes = 'itunes',
    Apple = 'apple',
}

export enum SubscriptionStatuses {
    NoSubscription = 'JarambaDefault', // No subscription
    ActiveSubscription = 'JarambaPremium', // Has valid subscription
    CancelledSubscription = 'JarambaFree', // Has cancelled subscription
}

export interface SubscriptionInfo {
    status: 'Trialing' | 'Active' | 'Canceled';
    provider: string;
    campaign: string;
    autoRenewalEnabled: boolean;
    discountApplied: boolean;
    created: string;
    nextBillingDate: string;
    billingInterval: 'Month' | 'Year' | 'NotSet';
}

export interface StripeSubscriptionManagementSessionRequest {
    ReturnUrl: string;
}

export interface StripeSubscriptionManagementSessionResponse {
    redirectUrl: string;
}

export type GetSubscriptionInfoResponse = SubscriptionInfo;

export interface ChurnOfferInfo {
    id: string;
    name: string;
    title: string;
    description: string;
    couponId: string;
}
