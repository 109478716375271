import CN from 'clsx';
import { useState, useEffect, useRef } from 'react';
import type { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import crossIcon from '@jaramba-frontend/core/assets/icons/cross.svg';

import Paper from '../Paper';
import styles from './styles.module.scss';

interface Props {
    isOpen: boolean;
    children: ReactNode;
    overlayClass?: string;
    contentClass?: string;
    closeButtonClass?: string;
    customCloseButton?: ReactNode;
    onClose: () => void;
}

const Modal = ({
    isOpen,
    children,
    overlayClass,
    contentClass,
    closeButtonClass,
    customCloseButton,
    onClose,
}: Props) => {
    const [modalContainer, setModalContainer] = useState<HTMLDivElement | null>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const modalElement = document.createElement('div');
        modalElement.className = 'modal-root';
        document.body.appendChild(modalElement);
        setModalContainer(modalElement);

        document.body.classList.add(styles.bodyScrollLock);

        return () => {
            if (modalElement.parentNode === document.body) {
                document.body.removeChild(modalElement);
                document.body.classList.remove(styles.bodyScrollLock);
            }
        };
    }, []);

    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && isOpen) {
                onClose();
            }
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('keydown', handleEscape);
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('keydown', handleEscape);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    useEffect(() => {
        if (modalContainer) {
            modalContainer.style.display = isOpen ? 'block' : 'none';

            if (isOpen) {
                modalRef.current?.focus();
            }
        }
    }, [isOpen, modalContainer]);

    if (!modalContainer || !isOpen) return null;

    return createPortal(
        <div className={CN(styles.modalOverlay, overlayClass)}>
            <Paper
                ref={modalRef}
                tabIndex={-1}
                role="dialog"
                aria-modal={isOpen}
                className={CN(styles.modalContent, contentClass)}
            >
                {customCloseButton || (
                    <button className={CN(styles.closeButton, closeButtonClass)} onClick={onClose}>
                        <img src={crossIcon} loading="lazy" draggable="false" />
                    </button>
                )}
                {children}
            </Paper>
        </div>,
        modalContainer
    );
};

export default Modal;
