import Button from '../Button';
import styles from './styles.module.scss';

interface Props {
    onHomeButtonClick: () => void;
}

const PageNotFound = ({ onHomeButtonClick }: Props) => {
    return (
        <div className={styles.root}>
            <h1 className="text-center">404</h1>
            <p className="text-center">
                Opppss... ser ut som något gick lite galet. Vi beklagar självklart detta. Det verkar som att den
                efterfrågade sidan inte kunde hittas på denna server. Hämtningen av sidan kan ha avbrutits eller så
                fyllde du i fel adress.
            </p>

            <div className="mx-auto text-center mt-8">
                <Button onClick={onHomeButtonClick}>Gå till startsidan</Button>
            </div>
        </div>
    );
};

export default PageNotFound;
