import { useEffect } from 'react';

import { Loading } from '@jaramba-frontend/core/components';

import styles from './styles.module.scss';

const LoadingScreen = () => {
    useEffect(() => {
        document.body.classList.add(styles.bodyScrollLock);

        return () => {
            document.body.classList.remove(styles.bodyScrollLock);
        };
    }, []);

    return (
        <div className={styles.root}>
            <Loading />
        </div>
    );
};

export default LoadingScreen;
