import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AnalyticsService } from '@jaramba-frontend/core/services';

const useAnalytics = () => {
    const location = useLocation();

    let lastTrackedPath: string | null = null;

    const handleTrackPageVisit = (path: string) => {
        if (path === lastTrackedPath) {
            return;
        }

        lastTrackedPath = path;

        try {
            AnalyticsService.tagPage(path);
        } catch (err) {
            console.error(err);
            throw new Error('Failed to tag page to GTM');
        }
    };

    useEffect(() => {
        AnalyticsService.init({
            gtmId: process.env.REACT_APP_GTM_ID ?? '',
            auth: process.env.REACT_APP_GTM_AUTH ?? '',
            preview: process.env.REACT_APP_GTM_PREVIEW ?? '',
        });

        AnalyticsService.tagEvent({
            event: 'originalLocation',
            originalLocation: window?.location?.href,
        });
    }, []);

    useEffect(() => {
        const path = `${location.pathname}${location.search}`;
        handleTrackPageVisit(path);
    }, [location.pathname, location.search]);
};

export default useAnalytics;
