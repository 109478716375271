import { useState, useRef, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import closeSvg from '@jaramba-frontend/core/assets/icons/close.svg';
import menuSvg from '@jaramba-frontend/core/assets/icons/hamburger.svg';
import { Button, JarambaLogo, MobileMenu } from '@jaramba-frontend/core/components';

import { navigationLinks, routes } from '../../constants';
import { useLogout } from '../../hooks';
import MenuItem from './MenuItem';
import styles from './styles.module.scss';

const AppHeaderMobile = () => {
    const headerRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const { handleLogout } = useLogout();

    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

    useEffect(() => {
        setMenuOpen(false);
    }, [location.pathname]);

    const handleToggleMenu = () => {
        setMenuOpen((prev) => !prev);
    };

    const handleCloseMenu = () => {
        setMenuOpen(false);
    };

    return (
        <header ref={headerRef} className={`${styles.navbar} flex flex-row items-center`}>
            <NavLink to={routes.HOME}>
                <JarambaLogo />
            </NavLink>

            <button className="md:hidden ml-auto" onClick={handleToggleMenu}>
                <img alt="Toggle menu" src={isMenuOpen ? closeSvg : menuSvg} width="33" height="33" />
            </button>

            <MobileMenu
                isMenuOpen={isMenuOpen}
                headerRef={headerRef}
                content={
                    <>
                        <ul>
                            {navigationLinks.map(({ pathname, label }, index) => (
                                <MenuItem key={`${index}_${pathname}`} href={pathname} label={label} />
                            ))}
                        </ul>

                        <div className="md:flex flex-row items-center justify-end ml-auto">
                            <Button
                                href={process.env.NEXT_PUBLIC_PARENT_PORTAL_URL}
                                label="Logga ut"
                                className={styles.logoutButton}
                                outlined
                                onClick={handleLogout}
                            />
                        </div>
                    </>
                }
                onMenuClose={handleCloseMenu}
            />
        </header>
    );
};

export default AppHeaderMobile;
