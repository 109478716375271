import CN from 'clsx';
import type { PropsWithChildren } from 'react';

import styles from './styles.module.scss';

interface Props {
    isGreen: boolean;
    isRed: boolean;
}

const Chip = ({ isGreen, isRed, children }: PropsWithChildren<Props>) => {
    return (
        <div
            className={CN(styles.subscriptionStatusChip, {
                [styles.green]: isGreen,
                [styles.red]: isRed,
            })}
        >
            {children}
        </div>
    );
};

export default Chip;
