import type { AxiosError } from 'axios';

import { apiService } from '.';
import type { CheckoutSessionResponse, CheckoutSessionRequest } from '../../checkout/src/types';

interface Params {
    token: string;
    successUrl: string;
    cancelUrl: string;
    priceLookupKey: string;
    campaignId?: string;
}

export const stripeCheckoutSession = async ({ token, successUrl, cancelUrl, priceLookupKey, campaignId }: Params) => {
    try {
        const res = await apiService.post<CheckoutSessionResponse, CheckoutSessionRequest>(
            '/payments/stripe/checkout-session',
            {
                campaignId,
                PriceLookupKey: priceLookupKey,
                Options: {
                    SuccessUrl: successUrl,
                    CancelUrl: cancelUrl,
                },
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                setApiVersionInHeader: true,
                apiVersion: 2,
            }
        );

        if (res.redirectToUrl) {
            return Promise.resolve(res.redirectToUrl);
        } else {
            return Promise.reject();
        }
    } catch (err: any) {
        return Promise.reject(err as AxiosError);
    }
};
