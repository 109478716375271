export enum Roles {
    User = 'user',
}

export interface UserLoginRequest {
    Username: string;
    Password: string;
}

export interface UserLoginResponse {
    Name: string;
    Token: string;
    RefreshToken: string;
    TokenExpirationDate: string;
    Roles: Roles[];
}

export interface ForgotPasswordRequest {
    Email: string;
}

export interface ForgotPasswordResponse {
    Response: number;
}

export interface ChangePasswordRequest {
    Password: string;
    NewPassword: string;
}

export interface ChangePasswordResponse {
    Status: string;
}

export interface ChangeForgottenPasswordRequest {
    UrlTicket: string;
    NewPassword: string;
}

export interface ChangeForgottenPasswordResponse extends Partial<UserLoginResponse> {
    UserAccountId?: number;
    UserName?: string;
    Response?: number;
    Ticket?: string;
}
