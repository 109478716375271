import CN from 'clsx';
import { forwardRef, useState } from 'react';

import hidePasswordIcon from '@jaramba-frontend/core/assets/icons/password_hidden.svg';
import showPasswordIcon from '@jaramba-frontend/core/assets/icons/password_shown.svg';

import styles from './styles.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    error?: string | null;
    className?: string;
}

const Input = forwardRef(
    (
        { label = '', error, className = '', value, type, disabled, ...rest }: InputProps,
        ref: React.Ref<HTMLInputElement>
    ) => {
        const [show, setShow] = useState<boolean>(type !== 'password');

        const getInputType = () => {
            if (show) {
                if (type !== 'password') {
                    return type;
                } else {
                    return 'text';
                }
            } else {
                return 'password';
            }
        };

        const handleShowPassword = () => {
            if (!disabled) {
                setShow(!show);
            }
        };

        return (
            <label className={CN(styles.label, className)}>
                {label && <span className={styles.labelText}>{label}</span>}
                <div className={styles.wrapper}>
                    <input
                        value={value}
                        disabled={disabled}
                        type={getInputType()}
                        className={CN(
                            styles.input,
                            { [styles.withValue]: Boolean(value) },
                            { [styles.withError]: error }
                        )}
                        ref={ref}
                        {...rest}
                    />

                    {type === 'password' && (
                        <>
                            {show ? (
                                <img
                                    src={showPasswordIcon}
                                    className={styles.icon}
                                    draggable={false}
                                    onClick={handleShowPassword}
                                />
                            ) : (
                                <img
                                    src={hidePasswordIcon}
                                    className={styles.icon}
                                    draggable={false}
                                    onClick={handleShowPassword}
                                />
                            )}
                        </>
                    )}
                </div>
                {error && (
                    <span className={styles.error} data-testid={`${rest.name}InputErrorMessage`}>
                        {error}
                    </span>
                )}
            </label>
        );
    }
);

export default Input;
