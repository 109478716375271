import type { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { Button, Input, Paper } from '@jaramba-frontend/core/components';
import { inputPasswordValidationRules } from '@jaramba-frontend/core/constants';

import type { User } from '../../types';
import styles from './styles.module.scss';

interface Props {
    loading: boolean;
    showSuccessMessage: boolean;
    userState: User | null;
    isExpiredTicket: boolean;
    responseErrors: Partial<AxiosError> | null;
    onInputsChange: () => void;
    onLoginToPortalClick: () => void;
    onForgotPasswordClick: () => void;
    onSubmit: (urlTicket: string | null, newPassword: string, hasInputErrors: boolean) => Promise<void>;
}

const ResetForgottenPasswordForm = ({
    loading,
    showSuccessMessage,
    userState,
    isExpiredTicket,
    responseErrors,
    onLoginToPortalClick,
    onInputsChange,
    onForgotPasswordClick,
    onSubmit,
}: Props) => {
    const [searchParams] = useSearchParams();
    const {
        formState: { errors },
        watch,
        register,
        handleSubmit,
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: { newPassword: '', confNewPassword: '' },
    });

    const hasInputErrors = Object.values(errors).length > 0 ? Object.values(errors).every((value) => !!value) : false;
    const urlTicket = searchParams.get('id');

    useEffect(() => {
        if (responseErrors) {
            onInputsChange();
        }
    }, [watch('newPassword'), watch('confNewPassword')]);

    return (
        <Paper className={styles.formContainer}>
            {!(showSuccessMessage || isExpiredTicket) && (
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(
                        async () => await onSubmit(urlTicket, watch('confNewPassword'), hasInputErrors)
                    )}
                >
                    <Input
                        {...register('newPassword', {
                            required: 'Nytt lösenord är obligatoriskt',
                            ...inputPasswordValidationRules,
                        })}
                        label="Nytt lösenord"
                        type="password"
                        placeholder="**********"
                        error={errors?.newPassword?.message}
                        disabled={loading}
                        autoComplete="new-password"
                    />
                    <Input
                        {...register('confNewPassword', {
                            required: 'Bekräfta nytt lösenord är obligatoriskt',
                            ...inputPasswordValidationRules,
                            validate: (value) => value === watch('newPassword') || 'Lösenorden stämmer inte överens',
                        })}
                        label="Bekräfta nytt lösenord"
                        type="password"
                        placeholder="**********"
                        error={errors?.confNewPassword?.message}
                        disabled={loading}
                        autoComplete="new-password"
                    />

                    <Button
                        label="Skicka"
                        className={styles.submitButton}
                        disabled={loading || !!responseErrors}
                        fullWidth
                    />
                </form>
            )}

            {showSuccessMessage && (
                <>
                    <p className="text-center">
                        Ditt lösenord har uppdaterats. Du kan nu logga in med ditt nya lösenord.
                    </p>

                    <Button
                        label="Logga in"
                        className={styles.submitButton}
                        disabled={loading || !!responseErrors}
                        fullWidth
                        onClick={onLoginToPortalClick}
                    />
                </>
            )}

            {responseErrors?.message && (
                <div className={styles.responseErrorsContainer}>
                    <p className={styles.responseError}>{responseErrors.message}</p>

                    {isExpiredTicket && (
                        <Button
                            label="Återställ lösenord"
                            className={styles.submitButton}
                            disabled={loading}
                            fullWidth
                            onClick={onForgotPasswordClick}
                        />
                    )}
                </div>
            )}
        </Paper>
    );
};

export default ResetForgottenPasswordForm;
