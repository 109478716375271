import CN from 'clsx';
import { InputHTMLAttributes, useState, useRef } from 'react';

import styles from './styles.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    hasWhiteBackground?: boolean;
}

const ToggleSwitch = ({ hasWhiteBackground = false, ...rest }: Props) => {
    const ref = useRef<HTMLInputElement | null>(null);
    const [isChecked, setIsChecked] = useState<boolean>(!!rest.checked);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLLabelElement>) => {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            if (ref.current) {
                setIsChecked((prev) => !prev);
                ref.current.checked = !ref.current.checked;

                if (rest.onChange) {
                    rest.onChange({
                        target: ref.current,
                    } as React.ChangeEvent<HTMLInputElement>);
                }
            }
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
        rest.onChange?.(e);
    };

    return (
        <label
            className={CN(
                styles.switchWrapper,
                { [styles.checked]: isChecked },
                { [styles.disabled]: rest.disabled },
                { [styles.white]: hasWhiteBackground }
            )}
            role="checkbox"
            tabIndex={!rest.disabled ? 0 : undefined}
            aria-label="Toggle switch"
            aria-checked={isChecked}
            aria-disabled={rest.disabled}
            onKeyDown={handleKeyDown}
        >
            <input ref={ref} type="checkbox" {...rest} tabIndex={-1} onChange={onChange} />
            <span className={styles.switch}>
                <span className={styles.switchHandle} />
            </span>
        </label>
    );
};
export default ToggleSwitch;
