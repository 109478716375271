import CN from 'clsx';
import type { FC } from 'react';

import styles from './styles.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLButtonElement> {
    label?: string;
    href?: string;
    fullWidth?: boolean;
    outlined?: boolean;
    NextLink?: FC<any>;
    RouterLink?: FC<any>;
    onClick?: () => Promise<void> | void;
}

const Button = ({
    href,
    label,
    outlined,
    disabled,
    fullWidth,
    className,
    children,
    tabIndex,
    NextLink,
    RouterLink,
    onClick,
}: Props) => {
    const commonProps = {
        tabIndex,
        children: children ?? label,
        className: CN(styles.root, className, {
            [styles.fullWidth]: fullWidth,
            [styles.outlined]: outlined,
            [styles.disabled]: disabled,
        }),
        disabled,
        onClick,
    };

    if (NextLink) {
        return <NextLink {...commonProps} href={href ?? ''} />;
    }

    if (RouterLink) {
        return <RouterLink {...commonProps} to={href ?? ''} />;
    }

    return <button {...commonProps} />;
};

export default Button;
