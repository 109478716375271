export const inputRequiredRule = {
    required: 'Bekräfta nytt lösenord är obligatoriskt',
};

export const inputPasswordValidationRules = {
    minLength: {
        value: 6,
        message: 'Lösenordets minsta längd är 6 tecken',
    },
    maxLength: {
        value: 64,
        message: 'Lösenordets maximal längd är 64 tecken',
    },
};
