import styles from './styles.module.scss';

const AccountDeletionInfo = () => {
    return (
        <p className={styles.deleteAccountInfo}>
            För att ta bort ditt konto behöver du <a href="mailto:support@jaramba.se">kontakta vår support</a>{' '}
            (support@jaramba.se) och begära en manuell kontoborttagning. När kontot har tagits bort kommer det att tas
            bort från vårt system, vilket innebär att du inte längre kommer att ha åtkomst till det. Observera att om du
            avslutar kontot kan du inte längre använda tjänsten och ingen återbetalning kommer göras (
            <a
                href={`${process.env.REACT_APP_WEBSITE_URL}/terms-and-conditions`}
                target="_blank"
                rel="noopener noreferrer"
            >
                se våra villkor
            </a>
            ).
        </p>
    );
};

export default AccountDeletionInfo;
