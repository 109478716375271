import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';

import { isProduction } from '@jaramba-frontend/core/utils';

import appReducer from './features/app/appSlice';
import productReducer from './features/product/productSlice';
import userReducer from './features/user/userSlice';
import persistConfig from './persistConfig';

const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    product: productReducer,
});

const persistedReducer: Reducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: !isProduction(),
});

export const persistor = persistStore(store);

export const getStoreState = (): RootState => store?.getState();

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
