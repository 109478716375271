import { Button, Modal } from '@jaramba-frontend/core/components';

import styles from './styles.module.scss';

interface Props {
    isOpen: boolean;
    onAccept: () => void;
    onClose: () => void;
}

const CancelSwedbankSubscriptionModal = ({ isOpen, onAccept, onClose }: Props) => {
    return (
        <Modal isOpen={isOpen} contentClass={styles.modalContent} onClose={onClose}>
            <h3>Vill du avsluta ditt Swedbank Pay abonnemang?</h3>
            <p className={styles.text}>Klicka på “Avsluta” nedan för att avsluta ditt Swedbank Pay abonnemang.</p>
            <div className={styles.buttonsContainer}>
                <Button onClick={onAccept}>Avsluta</Button>
                <Button className={styles.buttonGrey} onClick={onClose}>
                    Avbryt
                </Button>
            </div>
        </Modal>
    );
};

export default CancelSwedbankSubscriptionModal;
