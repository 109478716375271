import type { FC } from 'react';

import OctopusImage from '@jaramba-frontend/core/assets/images/confused_octopus.jpg';
import { Button, Paper } from '@jaramba-frontend/core/components';

import styles from './styles.module.scss';

interface Props {
    title: string;
    description: string;
    onAcceptOfferClick: () => void;
    onCancelClick: () => void;
}

const ChurnOffer: FC<Props> = ({ title, description, onAcceptOfferClick, onCancelClick }) => {
    return (
        <Paper className={styles.card}>
            <div className={styles.image}>
                <img src={OctopusImage} alt="Happy octopus" draggable={false} />
            </div>

            <div className={styles.content}>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.text}>{description}</p>
            </div>

            <div className={styles.buttons}>
                <Button onClick={onAcceptOfferClick}>Ja, jag vill fortsätta</Button>
                <Button outlined onClick={onCancelClick}>
                    Nej, jag vill avsluta
                </Button>
            </div>
        </Paper>
    );
};

export default ChurnOffer;
