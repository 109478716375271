import JarambaLogo from '../JarambaLogo';
import styles from './styles.module.scss';

const Header = () => {
    return (
        <header className={styles.header}>
            <JarambaLogo />
        </header>
    );
};

export default Header;
