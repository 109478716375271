import CN from 'clsx';
import { ReactNode, forwardRef } from 'react';

import styles from './styles.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    ref?: HTMLDivElement;
    className?: string;
}

const Paper = forwardRef(({ className, children, ...rest }: Props, ref: React.Ref<HTMLDivElement>) => (
    <div {...rest} ref={ref} className={CN(styles.root, className)}>
        {children}
    </div>
));

export default Paper;
