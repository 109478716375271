export const isProduction = () => {
    const env = process.env.NEXT_PUBLIC_ENVIRONMENT ?? process.env.REACT_APP_ENVIRONMENT ?? process.env.NODE_ENV;
    return env === 'production';
};

export const isDevelopment = () => {
    const env = process.env.NEXT_PUBLIC_ENVIRONMENT ?? process.env.REACT_APP_ENVIRONMENT ?? process.env.NODE_ENV;
    return env === 'development';
};

export const isServer = () => {
    return typeof window === 'undefined';
};

export const isClient = () => {
    return typeof window !== 'undefined';
};

export const isAndroid = (): boolean => /(android)/i.test(navigator.userAgent);

export const isIOS = (): boolean => /iPad|iPhone|iPod/.test(navigator.userAgent);
