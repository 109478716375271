import { useState, lazy, Suspense } from 'react';

import { appStoreLinks, mobileBreakpoint } from '@jaramba-frontend/core/constants';
import { useMediaMatch } from '@jaramba-frontend/core/hooks';
import { isIOS } from '@jaramba-frontend/core/utils';

import { AppHeader, AppQrBannerCard, AppStoreCard, JarambaAppCard } from '../../components';
import styles from './styles.module.scss';

const AppQrModal = lazy(() => import('../../components/AppDownloadCards/AppQrModal'));

const Download = () => {
    const isMobile = useMediaMatch(mobileBreakpoint);

    const [selectedStore, setSelectedStore] = useState<'apple' | 'google' | null>(null);

    const handleRedirectToStore = () => {
        if (isIOS()) {
            window.open(appStoreLinks.appStore, '_blank', 'noopener,noreferrer');
        } else {
            window.open(appStoreLinks.googlePlay, '_blank', 'noopener,noreferrer');
        }
    };

    const handleOpenAppQrModdal = (store: 'apple' | 'google') => {
        setSelectedStore(store);
    };

    const handleCloseModal = () => {
        setSelectedStore(null);
    };

    return (
        <>
            <AppHeader title="Nedladdning" />

            <div className={styles.pageContent}>
                <div className={styles.storeCardsCol}>
                    <JarambaAppCard onButtonClick={handleRedirectToStore} />

                    {!isMobile && (
                        <>
                            <AppStoreCard store="apple" onButtonClick={() => handleOpenAppQrModdal('apple')} />

                            <AppStoreCard store="google" onButtonClick={() => handleOpenAppQrModdal('google')} />
                        </>
                    )}
                </div>

                <AppQrBannerCard />
            </div>

            {selectedStore && (
                <Suspense fallback={null}>
                    <AppQrModal isOpen={!!selectedStore} store={selectedStore} onModalClose={handleCloseModal} />
                </Suspense>
            )}
        </>
    );
};

export default Download;
