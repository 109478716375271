import { NavLink, useLocation } from 'react-router-dom';

import styles from './styles.module.scss';

interface Props {
    label: string;
    href: string;
}

const MenuItem = ({ href, label }: Props) => {
    const location = useLocation();
    const isActiveRoute = location.pathname === href;

    return (
        <li className={styles.menuItem}>
            <NavLink className={isActiveRoute ? styles.active : ''} to={href}>
                {label}
            </NavLink>
        </li>
    );
};

export default MenuItem;
