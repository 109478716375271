import { PersistConfig } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';

import { isProduction } from '@jaramba-frontend/core/utils';

import { RootState } from './store';

const transforms = () => {
    if (!isProduction()) {
        return [];
    }

    return [
        encryptTransform({
            secretKey: 'jaramba-parent-portal-key',
            onError: (error) => {
                console.error(error);
            },
        }),
    ];
};

const persistConfig: PersistConfig<RootState> = {
    key: 'jaramba-parent-portal',
    storage,
    transforms: transforms(),
    whitelist: ['app', 'user', 'product'],
};

export default persistConfig;
